import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import SectionHead from './SectionHead'
import Img from 'gatsby-image'
import Reveal from 'react-reveal/Reveal';
import ExhibitionDates from './ExhibitionDates';

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, link, url } = this.props

    return (
      <section className="news">
        <SectionHead title={title} link={link} url={url} />
        <div className="container content">
          <div className="columns is-multiline">
            <Reveal effect="fadeInUpSlow">
            {posts.map(({ node: post }) => (
              <div
                className={"news__item column is-10-mobile is-offset-1-mobile is-offset-1-tablet is-5-tablet is-3-desktop is-offset-1-desktop is-paddingless-tablet " + post.slug}
                key={post.id}
              >
                  <div className="image column is-12">
                    { post.featured_media ?
                      <Img 
                        fluid={post.featured_media.localFile.childImageSharp.fluid} 
                        key={post.featured_media.id}/> 
                    : false }
                  </div>
                  <Link className="is-size-6 has-text-weight-bold" to={ "/press/" + post.slug}>
                    <h2>{post.title}</h2>
                    <ExhibitionDates start={post.date}/>
                  </Link>
                <div>
                  <div className="news__excerpt"
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt.replace(/<p className="link-more.*/, ''),
                    }}
                  />
                </div>
              </div>
            ))}
           </Reveal>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  link: PropTypes.string,
  url: PropTypes.string
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    featured_media {
      localFile {
        childImageSharp {
            # Try editing the "maxWidth" value to generate resized images.
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
          }
      }
    }
  }
`
